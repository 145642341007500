import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { graphql, Link } from 'gatsby'
import { ImHome } from "react-icons/im"
import { IoRocketOutline } from "react-icons/io5"
import Social from "../components/Social"
import SubscribeForm from "../components/SubscribeForm"
import MoreBlogPosts from "../components/MoreBlogPosts"
import kebabCase from "lodash/kebabCase"
import SDArticle from "../components/StructuredData/SDArticle"
 
const BlogPost = ({data, location, pageContext}) => {
    const {next, prev} = pageContext
    const post = data.postdata
    // const host = location.protocol + "//" + location.host
    const dateadd = new Date(post.frontmatter.date)
    const datemod = new Date(post.frontmatter.datemod)
    return (
        <>
            <Layout location={location}>
                <SDArticle url={location.href} title={post.frontmatter.title} image={post.frontmatter.featureimage.publicURL} datepub={post.frontmatter.sddate} datemod={post.frontmatter.sddatemod} />
                <SEO title={post.frontmatter.seotitle} description={post.frontmatter.seometadesc} image={post.frontmatter.featureimage.publicURL} />{/* title, description, image, article */}
                <PageHero heading={post.frontmatter.title} />
                <article className="container mx-auto max-w-7xl text-center pt-4">
                    <div className="px-8 pt-4">
                        <ul className="text-left font-mono text-xs md:text-base">
                          <li className="inline-block border-black border-r-2">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-2">Home</span>
                            </Link>
                          </li>
                          <li className="inline-block border-black border-r-2">
                            <Link to="/blog/">
                                <span className="px-2">Blog</span> 
                            </Link>
                          </li>
                          <li className="inline-block">
                            <span className="px-2">{post.frontmatter.title}</span>
                          </li>
                        </ul>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row">
                        <div className="p-6 lg:w-2/3">
                            {/* <h2 className="text-4xl font-mono text-left py-4">{post.frontmatter.title}</h2> */}
                            <div className="cmscontent" dangerouslySetInnerHTML={{ __html: post.html}}/>
                            <div className="flex flex-col justify-between sm:flex-row pt-8">
                                <div className="text-left pr-4">
                                    {prev && <>
                                        <Link className="text-sm md:text-base" to={prev.fields.slug}>Previous Post</Link>
                                        <br />
                                        <Link className="text-sm md:text-base" to={prev.fields.slug}>{prev.frontmatter.title}</Link>
                                    </>}
                                </div>
                                <div className="text-right pl-4">
                                    {next && <>
                                        <Link className="text-sm md:text-base" to={next.fields.slug}>Next Post</Link>
                                        <br />
                                        <Link className="text-sm md:text-base" to={next.fields.slug}>{next.frontmatter.title}</Link>
                                    </>}
                                </div>
                            </div>
                            {post.frontmatter.tags ? (
                                <div className="font-mono text-xs sm:text-base break-all text-center mx-8 mt-3">
                                    <span>Tags:</span>
                                    {post.frontmatter.tags.map(tag => (
                                        <span className="inline-block px-1 py-3" key={tag + `tag`}>
                                            [<Link to={`/tags/${kebabCase(tag)}/`} rel="tag">
                                                {tag}
                                            </Link>]
                                        </span>
                                    ))}
                                </div>
                            ) : null}
                            <div className="hidden lg:inline-block ">
                            <MoreBlogPosts curpost={post.fields.slug} />
                            </div>
                        </div>
                        <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                            <div className="lg:sticky top-24">
                                <div className="text-xs md:text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                                    <div className="inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                                    <p>Posted: {post.frontmatter.date}</p>
                                    {datemod > dateadd &&(<p>Updated: {post.frontmatter.datemod}</p>)}
                                </div>
                                <p className="text-xs hidden lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                                <p className="text-xs hidden lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                                <h3 className="hidden lg:inline-block pulse heading-box-text2 text-2xl pt-4 mx-8">
                                    <span>it's good to share!</span>
                                </h3>
                                <Social location={location} notitle={true} />
                                <div className="hidden lg:inline-block">
                                    <SubscribeForm formid="subscribe-form-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center lg:hidden">
                          <h3 className="pulse heading-box-text2 text-2xl pt-6 mx-8">
                            <span>it's good to share!</span>
                          </h3>
                            <Social location={location} notitle={true} />
                            <SubscribeForm formid="subscribe-form-2" />
                            <MoreBlogPosts curpost={post.fields.slug} />
                    </div>
                </article>
                
            </Layout>
        </>
    )
}

export default BlogPost

export const query = graphql`
query($slug: String!) {
    postdata: markdownRemark(fields: { slug: { eq: $slug }}) {
      html
      frontmatter {
          title
          sddate: date
          date(formatString: "DD MMMM, YYYY")
          sddatemod: datemod
          datemod(formatString: "DD MMMM, YYYY")
          description
          seotitle
          seometadesc
          tags
          featureimagealt
          featureimage {
            publicURL
          }
      }
      fields {
        slug
      }
    }
  }
`