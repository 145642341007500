import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const MoreBlogPosts = ({curpost, title="More News from the Blog"}) => {
    const {blogdata, homepagedata} = useStaticQuery(graphql`
        query {
            blogdata: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(blog)/.*[.]md$/"}}, 
                sort: {fields: [frontmatter___date], order: DESC}, 
                limit: 4
            ) {
            edges {
                node {
                fields {
                    slug
                }
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    title
                }
                }
            }
            }
            homepagedata: file(relativePath: {eq: "settings/settings-home.md"}) {
                childMarkdownRemark {
                    frontmatter {
                        allnewstext
                    }
                }
            }
        }
        `
    )
    const postsfull = blogdata
    const pageData = homepagedata.childMarkdownRemark.frontmatter
    return (
        <>
                <section className="container mx-auto text-center pt-8">
                    <h3 className="heading-box-text3 text-2xl md:text-3xl lg:text-4xl p-4 mx-8">
                        <span>
                            {title}
                        </span>
                    </h3>
                    <div className="text-left m-6">
                        {postsfull.edges.map(({node}) => {
                            const { frontmatter, fields } = node
                            const currentpost = (fields.slug === curpost)
                            return (
                                <div key={node.id} className="w-full hover:text-pink-700">
                                {!currentpost &&(
                                    <Link to={fields.slug} itemProp='url'>
                                        <h2 className="text-purple-900 text-lg sm:text-xl md:text-2xl text-left py-1 font-mono"><span>{frontmatter.title}</span></h2>
                                        <p className="text-xs md:text-base">Posted: {frontmatter.date}</p>
                                        <p className="text-lg sm:text-xl font-light text-left py-3">
                                            {node.excerpt}
                                        </p>
                                    </Link>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <h3 className="heading-box-text2 text-2xl md:text-3xl lg:text-4xl p-4 mx-8">
                        <Link to="/blog/" itemProp="url">
                            <span className="pulse">
                                {pageData.allnewstext}
                            </span>
                        </Link>
                    </h3>
                </section>
        </>
    )
}

export default MoreBlogPosts