import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

// JSON-LD

const SDArticle = ({ url, title, image, datepub, datemod, authortype, author, puborg, pubname, logo }) => {

    // feature: article
    // https://schema.org/Article   
    // https://developers.google.com/search/docs/data-types/article
    const imgSrc = "https://www.rokitmedia.co.uk"+image
    const writeData = () => {
        const scriptData = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": url
            },
            "headline": title,
            "image": [imgSrc],
            "datePublished": datepub,
            "dateModified": datemod,
            "author": {
                "@type": authortype,
                "name": author
            },
            "publisher": {
                "@type": puborg,
                "name": pubname,
                "logo": {
                    "@type": "ImageObject",
                    "url": logo
                }
            }
        }
    )
        return(
            scriptData
        )}
    return(
        <>
        <Helmet>
            <script type="application/ld+json">
                {writeData()}
            </script>
        </Helmet>
        </>
    )
}

export default SDArticle

SDArticle.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    datepub: PropTypes.string,
    datemod: PropTypes.string,
    authortype: PropTypes.string,
    author: PropTypes.string,
    puborg: PropTypes.string,
    pubname: PropTypes.string,
    logo: PropTypes.string,
  }

  SDArticle.defaultProps = {
    url: "https://www.rokitmedia.co.uk",
    title: "Rokit Media, Modern Website Development Agency",
    //image: "https://rokitmedia.netlify.app/images/home-page-feature-image.png", //DEV
    image: "https://www.rokitmedia.co.uk/images/home-page-feature-image.png", //PROD
    datepub: null,
    datemod: null,
    authortype: "Organization",
    author: "Rokit Media",
    puborg: "Organization",
    pubname: "Rokit Media",
    //logo: "https://rokitmedia.netlify.app/images/rokit-media-logo.png", //DEV
    logo: "https://www.rokitmedia.co.uk/images/rokit-media-logo.png", //PROD
  }